import {withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Optional, Skeletize} from "up-form";
const Summary = withStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    "& h1": {...theme.typography.h1},
    "& h2": {...theme.typography.h2},
    "& h3": {...theme.typography.h3},
    "& p": {...theme.typography.body1}
  }
}))(({classes, component: Component = "div"}) => {
  const {t} = useTranslation();
  return (
    <Skeletize rows={20} width="50em">
      <Component className={classes.root}>
        <Optional name="Summary.providerInfo">
          <h2>{t("Provider.fullName")}</h2>

          <p>
            {t("Provider.fullName")} is focused on helping {t("Provider.country.inhabitant")}s achieve their career aspirations and
            ensure they are prepared for future workforce needs.
          </p>
          <p>
            We support by keeping our students motivated and focused on achieving their career outcomes. Our world-class, online
            native learning platform allows you to study anytime, anywhere to fit study around your busy life.
          </p>
          <p>
            When you study at Swinburne Open Education, you know your learning is in the best hands, with education experience and
            prestige of our strategic partnership with Swinburne, and the commercial backing of one of the largest private education
            groups in APAC, UP Education.
          </p>
        </Optional>

        <h2>Course Information</h2>

        <p>
          It is important that you have reviewed all the course information provided to you on our website and in the course
          brochure<Optional name="Summary.workPlacement"> and where applicable, SWLA Guide linked to qualification criteria</Optional>.
          This includes minimum school completion; entry requirements including VET qualifications, industry experience and minimum
          age conditions.
        </p>

        <h2>Study Mode</h2>

        <p>
          The course is delivered in an online study mode via our online learning platform. You access all your course materials and
          submit your assessments online. Some courses require Structured Workplace Learning and Assessment (SWLA). See below for more detail on this.
        </p>

        <h2>Recognition of Prior Learning and Credit Transfer</h2>

        <p>
          If you have formal work experience or have completed previous studies related to this course, you are able to apply for
          credit transfers or recognition of prior learning.
        </p>

        <Optional name="Summary.studentServices">
          <h2>Student Services</h2>

          <p>
            Our friendly Student Support team are available to help you with all your non-academic specific questions. These may
            range from updating your student details to helping you resolve a technical issue.
          </p>
          <p>
            The best way to contact Student Services is via {t("Provider.phone.support")} or {t("Provider.email")}.
          </p>
          <p>
            Student Services are available to support students with all non-academic based queries.
          </p>
        </Optional>

        <Optional name="Summary.workPlacement">
          <h2>Structured Workplace Learning and Assessment (SWLA) </h2>

          <p>
            In some courses SWLA is required to ensure that learners can demonstrate and apply in an industry context what
            they have learnt. SWLA must be undertaken in an organisation that is suitable for the course and learners must
            be under the direction of an appropriately experienced supervisor. The specific requirements of the workplace are
            outlined in the SWLA Guide. Your workplace assessor will ascertain whether the workplace is appropriate.
          </p>
        </Optional>

        <h2>Cancellation </h2>

        <p>
          If you wish to terminate your studies, you must notify Swinburne Open Education in writing (Cancellation Request). If Swinburne Open Education receives your
          Cancellation Request within the Cooling Off Period which is seven (7) calendar days from the start date of this Contract,
          Swinburne Open Education will refund any Course Fees you have paid, less the applicable Administrative Fees as outlined in the Schedule of
          Administrative Fees.
        </p>

        <p>
          If you give Swinburne Open Education your Cancellation Request within any of the following periods after your start date, you will be liable to
          pay to Swinburne Open Education the following percentage of the full Course Fee plus the applicable Administrative Fees.
        </p>
        <ol>
          <li>During Block 1 (from the end of the Cooling off Period to half of your course duration), 50% of the Course Fees;</li>
          <li>
            From the commencement of Block 2, (second half of your course duration to the end of your duration), 100% of the Course
            Fees;
          </li>
        </ol>

        <h2>Course Extension </h2>
        <p>
          If you do not complete the course within the maximum duration time, the enrolment expires without refund. Where specific
          conditions have been met, an extension of up to 3 months may be granted subject to availability of course. You may apply
          for two extensions of up to 3 months to a maximum of six months during their enrolment period.
        </p>

        <h2>Confirmation of Enrolment</h2>
        <p>
          Once your enrolment is confirmed you will receive a formal Confirmation of Enrolment (COE) by email. Please ensure that
          the information you have provided is accurate.
        </p>
      </Component>
    </Skeletize>
  );
});

export default Summary;
