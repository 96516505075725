import {Stepper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Optional, Skeletize, useGlobal} from "up-form";
const Agreement_VET = withStyles((theme) => {
  return {
    root: {
      ...theme.typography.body1,
      "& h1": {...theme.typography.h1},
      "& h2": {...theme.typography.h2},
      "& h3": {...theme.typography.h3},
      "& p": {...theme.typography.body1},

      counterReset: "list-0 list-1 list-2",

      "& ol": {
        lineHeight: "1.5em"
      },

      "& ol.continue,& ol.continue-1,& ol.continue-2 ": {
        listStyle: "none !important"
      },

      "& ol.continue>li": {
        counterIncrement: "list-0"
      },

      "& ol.continue-1>li": {
        counterIncrement: "list-1"
      },
      "& ol.continue-2>li": {
        counterIncrement: "list-2"
      },

      "& ol.continue>li::marker": {
        content: "counter(list-0, decimal) '.\\00a0'"
      },
      "& ol.continue-1>li::marker": {
        content: "counter(list-1, lower-alpha) '.\\00a0'"
      },
      "& ol.continue-2>li::marker": {
        content: "counter(list-2, lower-roman) '.\\00a0'"
      },

      "& ol.alpha,& ol ol": {
        listStyle: "lower-alpha"
      },
      "& ol.roman>li, & ol ol ol": {
        listStyle: "lower-roman"
      }
    }
  };
})(({component: Component = Stepper, classes, renderPage = (index, children) => <>{children}</>, ...other}) => {
  const {t} = useTranslation();
  const {
    links: {policies}
  } = useGlobal();
  function Link({href, rel = "noopener noreferrer", target = "_blank", children, ...other}) {
    return (
      <a href={href} rel={rel} target={target} {...other}>
        {children || href}
      </a>
    );
  }
  return (
    <Skeletize rows={20} width="50em">
      <Component className={classes.root} {...other}>
        {renderPage(
          0,
          <>
            <h2>Student Agreement</h2>
            This is the Contract between you and Swinburne University of Technology (Provider Number 3059) ABN 13 628 586 699
            trading as Swinburne Open Education. It is an important document. This Contract sets out your obligations to SOE
            and Swinburne Open Educations obligations to you, and by accepting it you are acknowledging that you have read it and understand it. It is
            important that you understand that by accepting this Contract you are committing to paying the full course fees or a
            significant percentage of the full course fees unless you cancel your enrolment in writing to Swinburne Open Education within the cooling off
            period of seven days from the start date of this Contract (as defined in clause 4) or unless Special Consideration or
            guarantees under the {t("Provider.country.possessive")} Consumer Law apply to you. You cannot ask Swinburne Open Education to extend your
            course duration or defer your course or for Swinburne Open Education to issue your qualification certificate unless all course fees that are
            due at the time have been paid or unless Special Consideration or guarantees under the{" "}
            {t("Provider.country.possessive")} Consumer Law apply to you. Please take your time to review and confirm you understand
            all important elements of your Student Agreement
            <h2>Your Obligations</h2>
            <ol className="continue">
              <li>
                Your obligations under this Contract include:
                <ol>
                  <li>you agree to pay all fees associated with your course plus GST, if applicable (Course Fees); </li>
                  <li>
                    you agree to pay any Administrative Fees charged to you by Swinburne Open Education under the Schedule of Administrative Fees
                    published on the
                    <Link target="_blank" rel="noopener noreferrer" href={policies}>
                      Swinburne Open Education website
                    </Link>
                    (Administrative Fees);
                  </li>
                  <li>
                    you confirm that you fulfil all entry requirements and have the required equipment, as specified on the SOE
                    website, for the course in which you enrol;
                  </li>
                  <li>
                    you represent and warrant that all information you provided to Swinburne Open Education was accurate and complete and not misleading
                    and that you included all information that may impact on your ability to complete the course (such as a
                    disability);
                  </li>
                  <li>
                    you agree to comply with Swinburne Open Educations student policies and procedures as published on the Swinburne Open Education website (Student Policies
                    and Procedures);
                  </li>
                  <li>
                    you must have access to a computer, computer equipment, computer software and internet connection which meet
                    SOE's specifications from time to time while you are completing your course;
                  </li>
                  <li>
                    you may be required, depending on your particular course, to provide additional equipment or materials or
                    undertake additional studies or certifications as set out on the Swinburne Open Educations website at your own expense;
                  </li>
                  <li>
                    you must inform Swinburne Open Education in writing within seven (7) days of any corrections or changes to your personal details
                    including name, residential or postal address, email address and phone numbers;
                  </li>
                  <li>
                    you must maintain a current email address for the duration of your course as Swinburne Open Education will communicate with you via
                    email and through our Learning Management System; and
                  </li>
                  <li>
                    you must retain a copy of all assessments submitted to Swinburne Open Education for the duration of your course. Assessments
                    submitted by mail to Swinburne Open Education will not be returned to you.
                  </li>
                </ol>
              </li>
            </ol>
            <h2>Our Obligations</h2>
            <ol className="continue">
              <li>
                While you are complying with this Contract:
                <ol>
                  <li>
                    Swinburne Open Education will supply you with the materials for the first study period of your course which may be partly or fully
                    through our Learning Management System (LMS);
                  </li>
                  <li>
                    Swinburne Open Education will supply you with the materials for the subsequent study periods for your course after Swinburne Open Education have
                    determined, in its sole discretion, that you have successfully completed the prior study period for your course;
                  </li>
                  <li>
                    Swinburne Open Education will provide you with access to our LMS from your computer where that computer uses equipment, software and
                    internet capability which meet SOE's specifications from time to time;
                  </li>
                  <li>Swinburne Open Education will provide you with access to learning and administrative support;</li>
                  <li>
                    Swinburne Open Education will grade your assessments, or procure that a relevant Partner grades your assessments, and provide
                    feedback and grades for your assessments through our LMS; until the completion of your course or on the earlier
                    termination or end of this Contract; and
                  </li>
                  <li>
                    SUT will issue appropriate certification to you for your course once you have successfully completed all study
                    periods and paid all Course Fees
                  </li>
                </ol>
              </li>
            </ol>
  
            <h2>Accepting this Contract</h2>
            <ol className="continue">
              <li>
                You have accepted this Contract by:
                <ol>
                  <li>signing the Enroment Form; or</li>
                  <li>clicking “I Accept” on the webpage; or</li>
                  <li>clicking “I Accept” in the online enrolment system.</li>
                </ol>
              </li>
              <li>
                The start date of this Enrolment Contract (Enrolment Date) is the date on which all of the following conditions have
                been met:
                <ol>
                  <li>
                    you have accepted this Enrolment Contract in accordance with clause 3.
                  </li>
                  <li>
                    all Enrolment Conditions specified in your Application Form have been met. Enrolment Conditions may include
                    entry requirements, English language proficiency requirements or other pre-requisites; and
                  </li>
                  <li>Swinburne Open Education sends you your login details.</li>
                </ol>
              </li>
              <li>
                Swinburne Open Education enters into this Contract with you when it confirms your enrolment and provides a copy of this Contract to you.
              </li>
              <li>
                By accepting this Contract, you consent to Swinburne Open Education sending you electronic messages or contacting you at the phone number
                and email address you have provided to SOE, for the duration of this Contract and a reasonable period after the end
                of this Contract.
              </li>
              <li>
                If you are under 18 years of age, your parent or guardian must sign this Contract and complete the parent or
                guardian declaration form. Under this Contract, your parent or guardian is responsible for payment of Course Fees
                and Administrative Fees.
              </li>
            </ol>
            <Optional name="Agreement.workPlacement">
              <h2>Workplace Assessment Obligations</h2>
              <ol className="continue">
                <li>
                  If your course contains
                  <ol>
                    <li>
                      you must find a suitable workplace or workplaces to undertake the components of
                      your course (unless specified);
                    </li>
                    <li>
                      Swinburne Open Education will assess and determine, in its sole discretion, whether the workplace you identify is suitable for the
                      particular; and
                    </li>
                    <li>
                      you may be required to travel to an appropriate workplace (at your own cost) if you are unable to locate an
                      appropriate workplace within your local area; and
                    </li>
                  </ol>
                </li>
                <li>
                  Swinburne Open Education may undertake workplace assessments:
                  <ol>
                    <li>
                      in a range of ways, which may include completion of a logbook; telephone interviews with you and your
                      supervisor; visits at the worksite and completion of a portfolio; and
                    </li>
                    <li>
                      at approved workplaces. If you cannot attend the workplace on the assessment date you must notify Swinburne Open Education in
                      writing fourteen (14) days before the assessment date. Failure to attend the assessment or cancellation of the
                      assessment less than 14 days from the assessment date may result in an Administrative Fee being incurred,
                      which you must pay.
                    </li>
                  </ol>
                </li>
                <li>
                  To complete your course, you must complete all mandatory assessments within the maximum duration of your
                  course.
                </li>
              </ol>
            </Optional>
            <h2>Paying Your Course Fees</h2>
            <ol className="continue">
              <li>
                If you are paying your Course Fees via VET Student Loan, you must pay on or before the due date specified in your
                Application Form.
              </li>
              <li>
                Subject to the {t("Provider.country.possessive")} Consumer Law, if you do not pay the Course Fees by the due date
                then UP may:
                <ol>
                  <li>withhold the materials for your course; and/or</li>
                  <li>restrict access to our LMS; and/or</li>
                  <li>withhold the grading of assessments; and/or</li>
                  <li>cease or suspend any other obligation Swinburne Open Education or a Partner has under this Contract; and/or</li>
                  <li>notify relevant credit agencies of your default; and/or</li>
                  <li>withdraw you from the course.</li>
                </ol>
              </li>
            </ol>

       
            <h2>Course Duration </h2>
            <ol className="continue">
              <li>
                You must complete your course before the expiry date outlined in Swinburne Open Educations confirmation of your enrolment (Maximum
                Duration). Subject to the {t("Provider.country.possessive")} Consumer Law, if you do not complete the course within
                the Maximum Duration, your enrolment will expire and you will not be entitled to any refund or partial refund of
                Course Fees.
              </li>
            </ol>
            <h2>Course Extension and Deferral </h2>
            <ol className="continue">
              <li>
                You may apply for the changes specified in clause 15 to your enrolment by submitting the appropriate Request Form to
                Swinburne Open Education(including where applicable the relevant sections completed by a qualified Medical Doctor) and properly
                completing and lodging any paperwork Swinburne Open Education require to support your application. Subject to the
                {t("Provider.country.possessive")} Consumer Law, Swinburne Open Education will only consider your application if you have paid all Course
                Fees that are due at the time and you pay an Administrative Fee as outlined in the Schedule of Administrative Fees.
              </li>
              <li>
                The changes you may apply for are:
                <ol>
                  <li>
                    Course Extension: If you require more time to complete your studies, you can apply for a Course Extension before
                    the expiry date of your course and Swinburne Open Education will extend your course by up to six (6) months if you comply with clause
                    14 and:
                    <ol>
                      <li>there are no material changes to the training package for your course; and</li>
                      <li>your course is not in a teach-out period.</li>
                    </ol>
                    If Swinburne Open Education approve your Course Extension, the Maximum Duration of your course is adjusted accordingly.
                  </li>

                  <li>
                    Course Deferral: If you encounter difficulties or changed circumstances that are likely to impact on your
                    ability to study you may apply to defer your study for up to six (6) months if you comply with clause 14 and you
                    advise Swinburne Open Education of the date you expect to return to study.
                    <p>If Swinburne Open Education approve your application to defer your study, then Swinburne Open Education may:</p>
                    <ol>
                      <li>extend the Maximum Duration of your course by up to six (6) months;</li>
                      <li>
                        agree an alternative payment plan with you, which may include deferral of instalments for up to six (6)
                        months; and/or
                      </li>
                      <li>provide you with additional academic and learning support services.</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <h2>Changes During Your Studies</h2>
            <ol className="continue">
              <li>
                Swinburne Open Education provides the services on behalf of SUT, which is a Registered Training Organisation. As such it must comply with
                regulations relating to the courses it provides. You acknowledge that to comply with such regulations, Swinburne Open Education may be
                required to make changes to courses (including units, learning materials and assessments) and Student Policies and
                Procedures from time to time.
              </li>
              <li>
                In addition to changes required under clause 16, Swinburne Open Education may change the courses (including units, learning materials and
                assessments) and the Student Policies and Procedures from time to time.
              </li>
              <li>
                If a material change is made pursuant to clauses 16 or 17, then Swinburne Open Education will:
                <ol>
                  <li>give you 14 days’ notice (by email) before the change applies; and </li>
                  <li>
                    work with you to address any potential disadvantage you think may arise from the change, by, for example:
                    <ol>
                      <li>extending the Maximum Duration of your course;</li>
                      <li>giving you additional learning support services;</li>
                      <li>facilitating your transfer to a different Swinburne Open Education course; or</li>
                      <li>
                        giving you the option to complete your course (subject to availability and compliance with relevant
                        regulations).
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                If a material change is made pursuant to clause 16 and that change results in disadvantage which is unable to be
                addressed by clause 18, you may apply for Special Consideration under clause 27. If a material change is made
                pursuant to clause 17, and that change results in disadvantage which is unable to be addressed by clause 18, you may
                apply for Special Consideration under clause 27 or you may elect to cancel your enrolment and receive a refund of
                your Course Fees, less the applicable Administrative Fees as outlined in the Schedule of Administrative Fees
              </li>
              <li>
                Swinburne Open Education may update the Schedule of Administrative Fees, and such updates will be:
                <ol>
                  <li>made in February each year;</li>
                  <li>notified to you with 14 days’ notice (by email); and</li>
                  <li>limited to a maximum increase of 10% in any one calendar year.</li>
                </ol>
              </li>
            </ol>

       
            <h2>Cancellation and Refund</h2>
            <ol className="continue">
              <li>If you wish to terminate your studies, you must notify Swinburne Open Education in writing (Cancellation Request).</li>
              <li>
                Subject to the {t("Provider.country.possessive")} Consumer Law, if Swinburne Open Education receives your Cancellation Request within the
                Census Period which is thirty (30) calendar days from the start date of this Contract.
              </li>
            </ol>
            <h2>Guarantees under the {t("Provider.country.possessive")} Consumer Law </h2>
            <ol className="continue">
              <li>
                Our services come with guarantees that cannot be excluded under the {t("Provider.country.possessive")} Consumer Law
                and nothing in this Contract should be read as purporting to exclude the effect of the{" "}
                {t("Provider.country.possessive")} Consumer Law. These guarantees are that the services:
                <ol>
                  <li>
                    will be provided with acceptable care and skill or technical knowledge and taking all necessary steps to avoid
                    loss and damage;
                  </li>
                  <li>be fit for the purpose or give the results that Swinburne Open Education and the student agreed to; and</li>
                  <li>be delivered within a reasonable time frame when there is no agreed end date. </li>
                </ol>
              </li>
              <li>
                You are entitled to receive the services again or a refund for a major failure in the service and you may also be
                entitled to compensation for any other reasonably foreseeable loss or damage. You are entitled to receive the
                services again if the service fails to be of acceptable quality and the failure does not amount to a major failure.
              </li>
              <li>
                Where permissible, Swinburne Open Educations liability in relation to a failure to meet a consumer guarantee under the ACL is limited
                to:
                <ol className="continue-1">
                  <li>supplying the services again; or</li>
                  <li>the payment of the cost of having the services supplied again.</li>
                </ol>
              </li>
            </ol>
            <h2>Special Consideration</h2>
            <ol className="continue">
              <li>
                If you encounter difficulties or changed circumstances that are serious and continuing and are likely to materially
                impact on your ability to complete your course, you can apply for Special Consideration.
              </li>
              <li>
                If there has been a material change to your course under clause 16 or 17 resulting in material disadvantage to you
                which cannot be addressed under clause 18, you can apply for Special Consideration.
              </li>
              <li>
                To apply for Special Consideration, you must submit a Special Consideration Request Form to SOE, including the
                relevant sections completed by a qualified Medical Doctor (where applicable) and any other requested additional
                supporting documentation.
              </li>
              <li>
                If Special Consideration is granted Swinburne Open Education may agree to:
                <ol>
                  <li>extend the Maximum Duration of your course;</li>
                  <li>give you additional support services;</li>
                </ol>
              </li>
              <li>
                Without limitation, Special Consideration will not be given if you seek Special Consideration only on the basis
                that:
                <ol>
                  <li>you have changed jobs;</li>
                  <li>your work hours changed;</li>
                  <li>you have moved address (including inter-state or international moves);</li>
                  <li>you find the course more difficult, time consuming or stressful than you had expected; or</li>
                  <li>you have resigned from or terminated your employment.</li>
                  <li>
                    For clarification, a student is still eligible to apply for Special Consideration such as on financial hardship
                    or on medical grounds even where one of these factors also applies.
                  </li>
                </ol>
              </li>
            </ol>
            <h2>Other Terms</h2>
            <ol className="continue">
              <li>
                If your course prepares you to undertake external examinations, you are responsible for establishing your own
                eligibility and making arrangements to attend and pay for any fees and charges with respect to the external
                examination. Swinburne Open Education make no representations regarding external examinations.
              </li>
              <li>
                You may keep the material Swinburne Open Education provides to you. The content of the material is copyright and all intellectual
                property rights in the material remains the sole property of Swinburne Open Education or its nominated third party. You may not reproduce
                any part of the materials or assessments other than for personal, non-commercial use without Swinburne Open Educations prior written
                consent.
              </li>
              <li>
                This Contract is governed by and must be construed in accordance with the laws in force in Victoria. The parties
                submit to the exclusive jurisdiction of the courts of that State and the Commonwealth of Australia in respect of all
                matters arising out of or relating to this Contract, its performance or subject matter.
              </li>
            </ol>
      

            <h2>Privacy notice and student enrolment declaration</h2>
            <h3>Why We Collect Your Personal Information</h3>
            As a registered training organisation (RTO), we collect your personal information, so we can process and manage your
            enrolment in a vocational education and training (VET) course with us.
            <h3>How We Use Your Personal Information</h3>
            We use your personal information to enable us to deliver VET courses to you, and otherwise, as needed, to comply with
            our obligations as an RTO.
            <h3>How We Disclose Your Personal Information</h3>
            <p>
              We are required by law (under the National Vocational Education and Training Regulator Act 2011 (Cth) (NCETR Act)) to
              disclose the personal information we collect about you to the National VET Data Collection kept by the National Centre
              for Vocational Education Research Ltd (NCVER).
            </p>
            <p>
              The NCVER is responsible for collecting, managing, analysing, and communicating research and statistics about the
              Australian VET sector. We are also authorised by law (under the NVETR Act) to disclose your personal information to
              the relevant state or territory training authority.
            </p>
            <h3>How The NCVER And Other Bodies Handle Your Personal Information</h3>
            <p>
              The NCVER will collect, hold, and use and disclose your personal information in accordance with the law, including the
              Privacy Act 1988 (Cth) (Privacy Act) and the NVETR Act.
            </p>
            <p>
              Your personal information may be used and disclosed by NCVER for purposes that include populating authenticated VET
              transcripts; administration of VET; facilitation of statistics and research relating to education, including surveys
              and data linkage; and understanding the VET market.
            </p>
            <p>
              The NCVER is authorised to disclose information to the Australian Government Department of Education, Skills and
              Employment (DESE), Commonwealth authorities, State and Territory and authorities (other than registered training
              organisations) that deal with matters relating to VET and VET regulators for the purposes of those bodies, including
              to enable:
            </p>
            <ul>
              <li>administration of VET, including program administration, regulation, monitoring and evaluation</li>
              <li>facilitation of statistics and research relating to education, including surveys and data linkage</li>
              <li>understanding how the VET market operates, for policy, workforce planning and consumer information.</li>
            </ul>
            <p>
              The NCVER may also disclose personal information to persons engaged by NCVER to conduct research on NCVER’s behalf.
            </p>
            <p>The NCVER does not intend to disclose your personal information to any overseas recipients.</p>
            <p>
              For more information about how the NCVER will handle your personal information please refer to the NCVER’s Privacy
              Policy at <Link href="https://www.ncver.edu.au/privacy">www.ncver.edu.au/privacy</Link>
            </p>
            <p>
              If you would like to seek access to or correct your information, in the first instance, please contact your RTO using
              the contact details below.
            </p>
            <p>
              DESE is authorised by law, including the Privacy Act and the NVETR Act, to collect, use and disclose your personal
              information to fulfil specified functions and activities. For more information about how the DESE will handle your
              personal information, please refer to the DESE VET Privacy Notice at
              <Link href="https://www.dese.gov.au/national-vet-data/vet-privacy-notice">
                www.dese.gov.au/national-vet-data/vet-privacy-notice
              </Link>
            </p>
            <h3>Victorian Government Vet Student Enrolment Privacy Notice</h3>
            The Victorian Government, through the Department of Education and Training (the Department), develops, monitors, and
            funds vocational education and training (VET) in Victoria. The Victorian Government is committed to ensuring that
            Victorians have access to appropriate and relevant VET services. Any personal information collected by the Department
            for VET purposes is protected in accordance with the Privacy and Data Protection Act 2014 (Vic) and the Health Records
            Act 2001 (Vic).
            <b>Collection of your data:</b> Swinburne University is required to provide the Department with student and training
            activity data. This includes personal information collected in the Swinburne University enrolment form and unique
            identifiers such as the Victorian Student Number (VSN) and the Commonwealth’s Unique Student Identifier (USI). Swinburne
            University provides data to the Department in accordance with the Victorian VETStudent Statistical Collection
            Guidelines, available at:{" "}
            <Link href="https://www.education.vic.gov.au/training/providers/rto/Pages/datacollection.aspx">
              www.education.vic.gov.au/training/providers/rto/Pages/datacollection.aspx
            </Link>
            <p>I authorise Swinburne University to search for my existing USI in the USI Registry.</p>
            <p>
              <b>Use of your data:</b> The Department uses student and training data, including personal information, for a range of
              VET purposes including administration, monitoring and planning, including interaction between the Department and
              Student where appropriate. The data may also be subject to data analytics, which seek to determine the likelihood of
              certain events occurring (such as program or subject completion), which may be relevant to the services provided to
              the student.
            </p>
            <p>
              <b>Disclosure of your data:</b> As necessary and where lawful, the Department may disclose VET data, including
              personal information, to its contractors, other government agencies, professional bodies and/or other organisations
              for VET-related purposes. In particular, this includes disclosure of VET student and training data to the Commonwealth
              and the National Centre for Vocational Education Research (NCVER).
            </p>
            <p>
              <b>Legal and Regulatory:</b> The Department’s collection and handling of enrolment data and VSNs is authorised under
              the Education and Training Reform Act 2006 (Vic). The Department is also authorised to collect and handle USIs in
              accordance with the Student Identifiers Act 2014 (Cth) and the Student Identifiers Regulation 2014 (Cth).
            </p>
            <p>
              <b>Survey participation:</b> You may be contacted to participate in a survey conducted by NCVER or a
              Department-endorsed project, audit, or review relating to your training. This provides valuable feedback on the
              delivery of VET programs in Victoria.
            </p>
            <p>
              Please note you may opt out of the NCVER survey at the time of being contacted. Consequences of not providing your
              information: Failure to provide your personal information may mean that it is not possible for you to enrolin VET
              and/or to obtain a Victorian Government VET subsidy
            </p>
            <p>
              <b>Access, correction, and complaints:</b> You have the right to seek access to or correction of your own personal
              information. You may also complain if you believe your privacy has been breached.
            </p>
            <p>
              For further information, please contact Swinburne’s Privacy Unit in the first instance by phone 03 9214 5515 or
              email&nbsp;<Link href="mailto:infoprivacy@swin.edu.au">infoprivacy@swin.edu.au</Link>
            </p>
            <p>
              For further information about the way the Department collects and handles personal information, including access,
              correction, and complaints go to:{" "}
              <Link href="https://www.education.vic.gov.au/Pages/privacypolicy.aspx">
                www.education.vic.gov.au/Pages/privacypolicy.aspx
              </Link>
            </p>
            <p>
              For further information about Unique Student Identifiers, including access, correction, and complaints, go to:
              <Link href="https://www.usi.gov.au/Students/Pages/student-privacy.aspx">
                www.usi.gov.au/Students/Pages/student-privacy.aspx
              </Link>
            </p>

            <p>
              The student agreement is a formal contract outlining our obligations as an RTO and yours as a student. By clicking on
              the I ACCEPT button, you declare that you have read, and you agree to all the terms of this contract.
            </p>
            <p>In order to finalise your course enrolment please click on the I ACCEPT button below.</p>
          </>
        )}
      </Component>
    </Skeletize>
  );
});

export default Agreement_VET;
Agreement_VET.propTypes = {
  /**
   * render page of markup
   */
  renderPage: PropTypes.func
};
