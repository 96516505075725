/**
 * Checkout step
 */
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  makeStyles,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  useMediaQuery
} from "@material-ui/core";
import { Typography } from "up-form";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Payment from "@material-ui/icons/Payment";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardDatePicker as MuiDatePicker } from "@material-ui/pickers";
import { change, formValueSelector } from "redux-form";
import UpApi, { checkPhone } from "up-api";
import {
  BaseSection,
  Currency,
  fromId,
  fromLabel,
  InstallmentPaymentMethod,
  Message,
  PaymentMethod,
  PaymentPlanFrequency,
  SkeletonProvider,
  toId,
  useGlobal,
  //useSkeleton,
  Optional,
  useQuery,
  ChooseOne
} from "up-form";
import { useApplication, useMetadata, useCourses } from "up-state";
import ZipPricingPopup from "../ZipPricingPopup";
import ZipLandingPagePopup from "../ZipLandingPagePopup";
import ZipProductModal from "../ZipProductModal";

const moment = require("moment");
const today = moment().startOf("day");

const cellStyles = makeStyles((theme) => ({
  root: {
    padding: ".25em",
    borderBottom: "none",
  },
  head: {
    fontWeight: "bold",
  },
}));

let setGotPricing = false;
let pricing = {};
let payMethod = {};
let payFrequency = {};
let payPlanMethod = {};


const Checkout = ({ section, form }) => {
  //const pendingPreviousUpdates = useSkeleton();
  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    provider,
    component: {
      Checkout: {
        fullPaymentProvider,
        planPaymentProvider,
        checkPhoneCountry,
        overridePaymentPlanFrequency,
        overridePaymentInstallmentMethod,
        overridePaymentDate,
        noAlternativePaymentMethod,
        hideTotalAndDiscount,
        hasZipDirect
      } = {},
      SignUp: {
        validatePhoneNumber
      } = {}
    }
  } = useGlobal();
  const {
    pending: pendingMetadata,
    data: {
      paymentMethods,
      paymentProviders,
      paymentFrequencies,
      paymentInstallmentMethods,
    } = {},
  } = useMetadata();
  const fullPaymentMethod =
    paymentMethods && fromLabel(paymentMethods, "Paid in Full"); // Expected values
  const zipPaymentMethod = { label: 'Pay with Zip', value: { id: '809730000', label: 'Pay with Zip' } };
  const planPaymentMethod =
    paymentMethods && fromLabel(paymentMethods, "Payment Plan");
  const alternativePaymentMethod =
    paymentMethods && fromLabel(paymentMethods, "Alternative Payment");
  const isIAH = provider === "iahealth";
  const isColab = provider === "colab";

  const {
    data: {
      opportunity: {
        opportunityCRMId,
        intakeStartDate: applicationIntakeStartDate,
      } = {},
      student: { homePhone, mobilePhone } = {},
    } = {},
  } = useApplication();
  const { data: courses } = useCourses();

  // eslint-disable-next-line no-unused-vars
  //const {data: pricing, pending: pendingPricing} = useApplicationPricing({provider}); // oddly, we have to give the provider here as the LA doesn' work it out properly

  const selector = formValueSelector(form);
  const stateProductId = useSelector(
    (state) => selector(state, "chooseCourse.course.productId") || {}
  );
  const { pid: queryproductId } = useQuery();
  const productId =
    Object.keys(stateProductId).length > 0 ? stateProductId : queryproductId;
  let method = useSelector((state) => selector(state, `${section}.method`));
  let paymentProviderState = useSelector((state) => selector(state, `${section}.paymentProvider`));
  let paymentPlanMethod = useSelector((state) => selector(state, `${section}.installmentMethod`));
  let paymentPlanFrequency = useSelector((state) =>
    selector(state, `${section}.paymentPlanFrequency`)
  );
  let paymentPlanStartDate = useSelector((state) =>
    selector(state, `${section}.paymentPlanStartDate`)
  );
  //Repopulate Fields once entered
  if (paymentPlanFrequency) {
    payFrequency = paymentPlanFrequency;
  } else if (payFrequency && !paymentPlanFrequency) {
    dispatch(change(form, `${section}.paymentPlanFrequency`, payFrequency));
  }
  if (method) {
    payMethod = method;
  } else if (payMethod && !method) {
    dispatch(change(form, `${section}.method`, payMethod));
  }
  if (paymentPlanMethod) {
    payPlanMethod = paymentPlanMethod;
  } else if (payPlanMethod && !paymentPlanMethod) {
    dispatch(change(form, `${section}.installmentMethod`, payPlanMethod));
  }

  //const {data: createLeadResponse} = useSelector((state) => state.createLead || {});
  const isPaymentPlan =
    method && planPaymentMethod && method.id === planPaymentMethod.id;
  const courseFromProduct = courses.findIndex(
    (course) => course.productId === productId
  );
  const stateIntakeStartDate =
    courseFromProduct !== -1
      ? courses[courseFromProduct].intakeList[0].courseStartDate
      : undefined;
  const intakeStartDate = applicationIntakeStartDate || stateIntakeStartDate;
  const paymentPlanOffered = courses[courseFromProduct]?.paymentPlanOffered;
  
  const [loadedPricing, setLoadedPricing] = useState(false);

  useEffect(() => {
    console.log(loadedPricing)
  }, [loadedPricing]);

  useEffect(() => {
    if (
      !opportunityCRMId &&
      !setGotPricing &&
      productId !== undefined &&
      Object.keys(productId).length > 0
    ) {
      setGotPricing = true;
      UpApi.productPricing(productId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
      });
    } else if (opportunityCRMId && !setGotPricing) {
      setGotPricing = true;
      UpApi.applicationPricing(opportunityCRMId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
      });
    }
  }, [opportunityCRMId, provider, productId]);


  const minDate = moment().startOf("day").add(1, "day");
  const maxDate = moment
    .max(moment(intakeStartDate || undefined), moment())
    .add(7, "days")
    .startOf("day");
  const prefix = "Section.Checkout";
  const fullDiscount = 10; // TODO - get this returned
  const {
    productPriceCurrency,
    productName,
    productPrice: totalAmount,
  } = pricing || {};
  const paymentProvider =
    method && ((isPaymentPlan && hasZipDirect) ? paymentProviderState?.label :
      isPaymentPlan ? planPaymentProvider : fullPaymentProvider);
  const {
    calculatedDiscountAmount,
    extendedAmount: discountedTotalAmount,
    installments,
  } = (pricing &&
    pricing.paymentProviders &&
    pricing.paymentProviders[paymentProvider]) ||
    {};
  const {
    noOfPayments,
    installmentAmount,
    paymentPlanDepositAmount: depositAmount,
  } = (Array.isArray(installments) &&
    paymentPlanFrequency &&
    installments.find(
      ({ paymentFrequency }) => paymentFrequency === paymentPlanFrequency.id
    )) ||
    {};
  const [
    { valid: phoneValid, pending: pendingPhoneCheck } = {},
    setPhoneValidation,
  ] = useState(undefined);
  const { phone: signupPhone } = useSelector(
    (state) => selector(state, "signup") || {}
  );
  const phone = homePhone || mobilePhone || signupPhone;
  const pending = pendingMetadata;

  useEffect(() => {
    if (
      phone &&
      checkPhoneCountry &&
      fullPaymentMethod &&
      !pendingPhoneCheck &&
      phoneValid === undefined
    ) {
      setPhoneValidation({ pending: true });
      checkPhone(phone, checkPhoneCountry)
        .then(({ valid, type, formatted }) => {
          setPhoneValidation({ pending: false, valid });
          if (!valid && noAlternativePaymentMethod && validatePhoneNumber && !hasZipDirect) {
            // Force to use Full Payment as phone no not home country and no Alternative payment option
            dispatch(change(form, `${section}.method`, fullPaymentMethod));
          }
        })
        .catch((error) => {
          console.error("Could not validate " + phone);
          setPhoneValidation({ pending: false, error, valid: false });
        });
    }
  }, [
    checkPhoneCountry,
    dispatch,
    form,
    fullPaymentMethod,
    phone,
    section,
    pendingPhoneCheck,
    phoneValid,
    noAlternativePaymentMethod,
    validatePhoneNumber,
    hasZipDirect
  ]);

  useEffect(() => {
    paymentProviders &&
      paymentProvider &&
      dispatch(
        change(
          form,
          `${section}.paymentProvider`,
          fromLabel(paymentProviders, paymentProvider)
        )
      ); // set derived field
  }, [form, paymentProvider, paymentProviders, section, dispatch]);

  useEffect(() => {
    overridePaymentPlanFrequency &&
      isPaymentPlan &&
      dispatch(
        change(
          form,
          `${section}.paymentPlanFrequency`,
          fromLabel(paymentFrequencies, overridePaymentPlanFrequency)
        )
      ); // set derived field
  }, [
    form,
    overridePaymentPlanFrequency,
    paymentFrequencies,
    section,
    dispatch,
    isPaymentPlan,
  ]);

  useEffect(() => {
    overridePaymentInstallmentMethod &&
      dispatch(
        change(
          form,
          `${section}.installmentMethod`,
          fromLabel(paymentInstallmentMethods, overridePaymentInstallmentMethod)
        )
      ); // set derived field
  }, [
    form,
    overridePaymentInstallmentMethod,
    paymentInstallmentMethods,
    section,
    dispatch,
  ]);

  useEffect(() => {
    overridePaymentDate &&
      isPaymentPlan &&
      dispatch(change(form, `${section}.paymentPlanStartDate`, today)); // set derived field
  }, [form, overridePaymentDate, section, dispatch, isPaymentPlan]);

  useEffect(() => {
    if (isPaymentPlan && hasZipDirect && !paymentPlanOffered) {
      dispatch(change(form, `${section}.paymentProvider`, fromLabel(paymentProviders, "Zip AU")));
    }
  }, [isPaymentPlan, hasZipDirect, paymentPlanOffered, paymentProviders, form, section, dispatch]);


  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    dispatch(change(form, `${section}.paymentPlanStartDate`, date));
  };

  function RenderCurrency({ value, signDisplay = "auto", ...other }) {
    return (
      <Currency
        component="span"
        formatOptions={{ signDisplay }}
        currency={productPriceCurrency}
        value={Number(value)}
        {...other}
      />
    );
  }
  function PaymentSummary({
    totalAmount,
    discountedTotalAmount,
    noOfPayments,
    installmentAmount,
    depositAmount,
    installmentFrequency,
  }) {
    const classes = cellStyles();
    function SummaryLine({ i18nKey, children }) {
      return (
        <TableRow>
          <TableCell variant="head" classes={classes}>
            {t(`${prefix}.paymentSummary.${i18nKey}`)}:
          </TableCell>
          <TableCell classes={classes} style={{ textAlign: "right" }}>
            {children}
          </TableCell>
        </TableRow>
      );
    }
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Payment
              fontSize="large"
              color="secondary"
              style={{ verticalAlign: "middle", marginRight: ".5em" }}
            />
            {t(`${prefix}.paymentSummary.title`)}
          </Typography>
          <Table>
            <TableBody>
              {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
              {!!totalAmount && !hideTotalAndDiscount && (
                <SummaryLine i18nKey="totalAmount">
                  <RenderCurrency value={totalAmount} />
                </SummaryLine>
              )}
              {!hideTotalAndDiscount && !!totalAmount && (
                <SummaryLine i18nKey="discountAmount">
                  <RenderCurrency
                    signDisplay="always"
                    value={-Number(calculatedDiscountAmount || 0)}
                  />
                </SummaryLine>
              )}
              {!!discountedTotalAmount && (
                <SummaryLine i18nKey="discountedTotalAmount">
                  <RenderCurrency value={discountedTotalAmount} />
                </SummaryLine>
              )}
            </TableBody>
          </Table>

          {noOfPayments >= 2 && paymentProviderState && paymentProviderState?.id === toId(fromLabel(paymentProviders, "Stripe Subscription AU")) && (
            <>
              {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
              <Typography variant="h2" gutterBottom>
                <CalendarToday
                  fontSize="large"
                  color="secondary"
                  style={{ verticalAlign: "middle", marginRight: ".5em" }}
                />
                {t(`${prefix}.paymentSchedule.title`)}
              </Typography>
              <Table>
                <TableBody>
                  {!!depositAmount && (
                    <SummaryLine i18nKey="depositAmount">
                      <RenderCurrency value={depositAmount} />
                    </SummaryLine>
                  )}
                  <SummaryLine i18nKey="noOfPayments">
                    {noOfPayments}
                  </SummaryLine>
                  {installmentFrequency && (
                    <SummaryLine i18nKey="installmentFrequency">
                      {installmentFrequency}
                    </SummaryLine>
                  )}
                  {installmentAmount && (
                    <SummaryLine i18nKey="installmentAmount">
                      <RenderCurrency value={installmentAmount} />
                    </SummaryLine>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <SkeletonProvider busy={pending}>
      <BaseSection section={section} title={t(`${prefix}.title`)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {productName && (
              <Typography gutterBottom variant="h2">
                {productName}
              </Typography>
            )}
            <Trans
              i18nKey={paymentPlanOffered ? `${prefix}.intro` : `${prefix}.noPaymentPlanOfferedIntro`}
              components={{
                heading: <Typography variant="h2" />,
                subheading: <Typography variant="h3" />,
                para: <Typography paragraph variant="body1" />,
                learnMorePopup: hasZipDirect ? <ZipLandingPagePopup /> : <ZipPricingPopup />
              }}
              values={{ fullDiscount }}
            ></Trans>
          </Grid>
          <Grid item xs={12}>
            {!pendingPhoneCheck && (
              <PaymentMethod
                name="method"
                label={t(`${prefix}.method.label`)}

                //  disabled={!phoneValid && noAlternativePaymentMethod} // hardcodeed to full payment if phone isn't validated and no alternative payment method
                required
                transform={(options) =>
                  options
                    .filter(({ value: { id } }) =>
                      isIAH && ((!phoneValid && phone) || !paymentPlanOffered)
                        ? [
                          fullPaymentMethod.id,
                          alternativePaymentMethod.id
                        ].includes(id)
                        : isIAH
                          ? [
                            fullPaymentMethod.id,
                            planPaymentMethod.id,
                            alternativePaymentMethod.id,
                          ].includes(id)
                          : isColab
                            ? [
                              fullPaymentMethod.id
                            ].includes(id)
                            : [
                              fullPaymentMethod.id,
                              planPaymentMethod.id
                            ].includes(id)
                    ).concat(isColab ? zipPaymentMethod : [])
                    .map(({ label, value }) => ({
                      label:
                        value.id === fullPaymentMethod.id && value.label === 'Paid in Full'
                          ? "Pay the full amount"
                          : label, // Sel doesn't like current CRM value of Paid in Full
                      value,

                    }))
                }
              />
            )}
          </Grid>
          {isPaymentPlan && hasZipDirect && (
            <Grid item xs={12}>
              <ChooseOne
                name="paymentProvider"
                label="Payment Provider"
                required
                options={paymentProviders.filter((provider) => (provider?.label === "Zip AU" || (paymentPlanOffered && provider?.label === "Stripe Subscription AU"))).map((provider) => ({
                  label: provider?.id === toId(fromLabel(paymentProviders, "Zip AU")) ? "Pay with Zip" : "Payment Plan with Stripe Billing",
                  value: provider
                }))}
              />
            </Grid>
          )}
          {paymentProviderState && paymentProviderState?.id === toId(fromLabel(paymentProviders, "Stripe Subscription AU")) && (
            <>
              <Optional name="Checkout.installmentMethod">
                <Grid item xs={12} sm={6}>
                  <InstallmentPaymentMethod
                    name="installmentMethod"
                    label={t(`${prefix}.installmentMethod.label`)}
                    required
                  />
                </Grid>
              </Optional>
              <Optional name="Checkout.paymentPlanFrequency">
                <Grid item xs={12} sm={12}>
                  <PaymentPlanFrequency
                    name="paymentPlanFrequency"
                    label={t(`${prefix}.paymentPlanFrequency.label`)}
                    //filter={({ label }) => !/Monthly/.test(label)}
                    required
                  />
                </Grid>
              </Optional>
            </>
          )}
            <Grid item container xs={12} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <PaymentSummary
                  {...{
                    totalAmount,
                    discountedTotalAmount,
                    noOfPayments,
                    installmentAmount,
                    depositAmount,
                    installmentFrequency:
                      paymentPlanFrequency && paymentPlanFrequency.label,
                    paymentPlanStartDate,
                  }}
                />
                {paymentProviderState && paymentProviderState?.id === toId(fromLabel(paymentProviders, "Stripe Subscription AU")) && (
                  <Optional name="Checkout.paymentPlanStartDate">
                    <Grid container item xs={12} justifyContent="center">
                      <Grid item sm={6}>
                        <Box marginTop={2} marginBottom={1} display="flex" justifyContent="center">
                          <InputLabel>
                            {t(`${prefix}.paymentPlanStartDate.label`)}
                          </InputLabel>
                        </Box>
                      </Grid>
                      <Box display="flex" justifyContent="center">
                        <SkeletonProvider>
                          <MuiDatePicker
                            autoOk={true}
                            required
                            showTodayButton={true}
                            value={selectedDate}
                            label={t(`${prefix}.paymentPlanStartDate.label`)}
                            minDate={minDate}
                            maxDate={maxDate}
                            format="YYYY-MM-DD"
                            inputValue={inputValue}
                            onChange={onDateChange}
                            orientation={isSmallScreen ? "portrait" : "landscape"}
                            variant="static"
                            data-name="paymentPlanStartDate"
                            {...{
                              minDate,
                              maxDate,
                            }}
                          />
                        </SkeletonProvider>
                      </Box>
                    </Grid>
                  </Optional>)}
                {isPaymentPlan && paymentProviderState && paymentProviderState.label === 'Zip AU' && (<ZipProductModal section={section} form={form} amount={discountedTotalAmount} />)}
              </Grid>
            </Grid>
        </Grid>

        {pending && (
          <Message
            open={pending}
            variant="busy"
            message={t(`${prefix}.busy`)}
          />
        )}
      </BaseSection>
    </SkeletonProvider>
  );
};

Checkout.propTypes = {};

export default Checkout;

const dateFormat = "YYYY-MM-DD";

export function mapToApplication(
  {
    method,
    paymentProvider,
    installmentMethod,
    paymentPlanFrequency,
    paymentPlanStartDate
  },
  {
    metadata: {
      paymentMethods
    }
  }
) {
  return {
    payment:
      method.label === "Alternative Payment"
        ? { methodId: toId(method) }
        : paymentProvider.label === "Zip AU"
          ? { methodId: toId(fromLabel(paymentMethods, "Paid in Full")), paymentProviderId: toId(paymentProvider) }
          : {
            methodId: toId(method),
            installmentMethodId: toId(installmentMethod),
            paymentPlanFrequencyId: toId(paymentPlanFrequency),
            paymentPlanStartDate:
              paymentPlanStartDate &&
              moment(paymentPlanStartDate).format(dateFormat),
            paymentProviderId: toId(paymentProvider),
          }
  };
}

export function mapFromApplication(
  {
    payment: {
      methodId,
      installmentMethodId,
      paymentPlanFrequencyId,
      paymentPlanStartDate,
      paymentProviderId,
    } = {},
  } = {},
  {
    metadata: {
      paymentMethods,
      paymentInstallmentMethods,
      paymentFrequencies,
      paymentProviders,
    },
  }
) {
  return {
    method: fromId(paymentMethods, methodId),
    installmentMethod: fromId(paymentInstallmentMethods, installmentMethodId),
    paymentPlanFrequency: fromId(paymentFrequencies, paymentPlanFrequencyId),
    paymentPlanStartDate:
      (paymentPlanStartDate && moment(paymentPlanStartDate)) || undefined,
    paymentProvider: fromId(paymentProviders, paymentProviderId),
  };
}
