import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { BaseSection, useGlobal, fromLabel, useQuery, Typography, toId, Currency } from 'up-form';
import {Grid, makeStyles, TableRow, TableCell, CircularProgress, Table, TableBody} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {createStripeIntentAndSubscription, useMetadata} from "up-state";
import { change, formValueSelector } from "redux-form";
import UpApi from "up-api";
import { Trans, useTranslation } from "react-i18next";
import StripePaymentElement from './StripePaymentElement';
import { loadStripe } from "@stripe/stripe-js";
import { createStripeTheme } from '../../themes';

let setGotPricing = false;
let pricing = {};

const cellStyles = makeStyles((theme) => ({
    root: {
      padding: "1em",
    }
  }));

const useStyles = makeStyles((theme) => ({
    rightMargin: {
      [theme.breakpoints.up('md')]: {
        marginRight: '40px',
    }
  }
}));

const StripePaymentPlan = ({ section, form }) => {
    const { t } = useTranslation();
    const prefix = "Section.StripePaymentPlan";
    const styling = useStyles();
    const dispatch = useDispatch();
    const {
        provider,
        component: {
            Checkout: {
              fullPaymentProvider,
              planPaymentProvider,
              hideTotalAndDiscount
            } = {}
          },
        stripePublishableKey
    } = useGlobal();
    const {data: {clientId: clientSecret, subscriptionScheduleId} = {}} = useSelector((state) => state.createStripeIntentAndSubscription || {});
    const {data: application} = useSelector((state) => state.application || {});
    let {
        opportunity: {opportunityCRMId: opportunityId} = {},
        payment: {firstPaymentTransactionId, methodId, paymentPlanFrequencyId, paymentPlanStartDate} = {},
      } = application || {};
    const {
      data: {
        paymentMethods,
        paymentProviders
      } = {},
    } = useMetadata();
    const planPaymentMethod =
    paymentMethods && fromLabel(paymentMethods, "Payment Plan");

    const selector = formValueSelector(form);
    const stateProductId = useSelector(
        (state) => selector(state, "chooseCourse.course.productId") || {}
    );
    const { pid: queryproductId } = useQuery();
    const productId =
        Object.keys(stateProductId).length > 0 ? stateProductId : queryproductId;
        
    const isPaymentPlan =
    methodId && planPaymentMethod && methodId === planPaymentMethod.id;
    const paymentProvider =
      isPaymentPlan ? planPaymentProvider : fullPaymentProvider;
    const {
    calculatedDiscountAmount,
    extendedAmount: discountedTotalAmount,
    installments,
    } = (pricing &&
    pricing.paymentProviders &&
    pricing.paymentProviders[paymentProvider]) || {};
    const {
        noOfPayments,
        installmentAmount,
        paymentPlanDepositAmount: depositAmount,
      } = (Array.isArray(installments) &&
      paymentPlanFrequencyId &&
        installments.find(
          ({ paymentFrequency }) => paymentFrequency === paymentPlanFrequencyId
        )) ||
        {};
  
    const [stripePromise, setstripePromise] = useState(null);
    useEffect(() => {
      if (!stripePromise) {
        const promise =  stripePublishableKey && loadStripe(stripePublishableKey);
        setstripePromise(promise);
      }
    }, [stripePromise, stripePublishableKey]);

    const options = {
      appearance: createStripeTheme(provider),
      mode: "setup",
      currency: "aud"
    };

    const [loadedPricing, setLoadedPricing] = useState(false);
    useEffect(() => {
      console.log(loadedPricing)
    }, [loadedPricing]);

    useEffect(() => {
    if (
        !opportunityId &&
        !setGotPricing &&
        productId !== undefined &&
        Object.keys(productId).length > 0
    ) {
        setGotPricing = true;
        UpApi.productPricing(productId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
        });
    } else if (opportunityId && !setGotPricing) {
        setGotPricing = true;
        UpApi.applicationPricing(opportunityId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
        });
      }
    }, [opportunityId, provider, productId]);

    useEffect(() => {
        if (!clientSecret && opportunityId && firstPaymentTransactionId && !subscriptionScheduleId) {
            dispatch(
              createStripeIntentAndSubscription({
                provider: provider,
                createType: "SetupIntent"
              })
            );
        }
      }, [dispatch, provider, clientSecret, opportunityId, firstPaymentTransactionId, subscriptionScheduleId]);

    useEffect(() => {
        if (installmentAmount && noOfPayments) {
            dispatch(change(form, `${section}.noOfPayments`, noOfPayments));
            dispatch(change(form, `${section}.installmentAmount`, installmentAmount));
        }
    }, [installmentAmount, noOfPayments, dispatch, form, section]);
      
    const {
      productPriceCurrency,
      productName,
      productPrice: totalAmount,
    } = pricing || {};

    let paymentProviderState = useSelector((state) => selector(state, `checkout.paymentProvider`));

    let paymentPlanFrequency = useSelector((state) =>
        selector(state, `checkout.paymentPlanFrequency`)
      );

    function RenderCurrency({ value, signDisplay = "auto", ...other }) {
      return (
        <Currency
          component="span"
          formatOptions={{ signDisplay }}
          currency={productPriceCurrency}
          value={Number(value)}
          style={{ fontWeight: "600" }}
          {...other}
        />
      );
    };

    function PaymentSummary({
      totalAmount,
      discountedTotalAmount,
      noOfPayments,
      installmentAmount,
      depositAmount,
      installmentFrequency,
    }) {
      const classes = cellStyles();
      function SummaryLine({ i18nKey, children }) {
        return (
          <TableRow>
            <TableCell variant="head" classes={classes}>
              {t(`${prefix}.paymentSummary.${i18nKey}`)}
            </TableCell>
            <TableCell classes={classes} style={{ textAlign: "right", fontWeight: "600" }}>
              {children}
            </TableCell>
          </TableRow>
        );
      }
      return (
        <Grid container direction="column" spacing={1}>
          <Grid item>
          <Table>
            <TableBody>
              <>
                <TableRow>
                  <TableCell variant="head" style={{fontSize: '20px'}}>
                      Summary
                  </TableCell>
                  <TableCell classes={classes} style={{ textAlign: "right" }}>
                  </TableCell>
                </TableRow>
                <SummaryLine i18nKey="courseName">
                      {productName}
                </SummaryLine>
              </>
              {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
              {!!totalAmount && !hideTotalAndDiscount && (
                <SummaryLine i18nKey="totalAmount">
                  <RenderCurrency value={totalAmount} />
                </SummaryLine>
              )}
              {!hideTotalAndDiscount && !!totalAmount && (
                <SummaryLine i18nKey="discountAmount">
                  <RenderCurrency
                    signDisplay="always"
                    value={-Number(calculatedDiscountAmount || 0)}
                  />
                </SummaryLine>
              )}
              {!!discountedTotalAmount && (
                <SummaryLine i18nKey="discountedTotalAmount">
                  <RenderCurrency value={discountedTotalAmount} />
                </SummaryLine>
              )}
            </TableBody>
          </Table>
          </Grid>
          {noOfPayments >= 2 && paymentProviderState && paymentProviderState?.id === toId(fromLabel(paymentProviders, "Stripe Subscription AU")) && (
            <Grid item>
              {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
              <Table>
                <TableBody>
                  <TableRow>
                      <TableCell variant="head" style={{fontSize: '20px'}}>
                          Schedule
                      </TableCell>
                      <TableCell classes={classes} style={{ textAlign: "right" }}>
                    </TableCell>
                </TableRow>
                  {!!depositAmount && (
                    <SummaryLine i18nKey="depositAmount">
                      <RenderCurrency value={depositAmount} />
                    </SummaryLine>
                  )}
                  <SummaryLine i18nKey="totalInstallmentAmount">
                    <RenderCurrency value={(discountedTotalAmount - depositAmount)} />
                  </SummaryLine>
                  <SummaryLine i18nKey="noOfPayments">
                    {noOfPayments}
                  </SummaryLine>
                  {installmentFrequency && (
                    <SummaryLine i18nKey="installmentFrequency">
                      {installmentFrequency}
                    </SummaryLine>
                  )}
                  {installmentAmount && (
                    <SummaryLine i18nKey="installmentAmount">
                      <RenderCurrency value={installmentAmount} />
                    </SummaryLine>
                  )}
                </TableBody>
              </Table>
               </Grid>
          )}
        </Grid>
      );
    };

    return (
    <BaseSection section={section}>
        <Grid container item xs={12} md={6} className={styling.rightMargin}>
            <Grid>
              <Typography variant="h1" gutterBottom>
                Payment Plan
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <Trans t={t} i18nKey={`${prefix}.paymentPlanDescription`} />
              </Typography>
            </Grid>
            {productName && (
              <Grid item container>
                <PaymentSummary
                    {...{
                    totalAmount,
                    discountedTotalAmount,
                    noOfPayments,
                    installmentAmount,
                    depositAmount,
                    installmentFrequency:
                        paymentPlanFrequency && paymentPlanFrequency.label,
                    paymentPlanStartDate,
                    }}
                />
              </Grid>
            )}
        </Grid>
        <Elements stripe={stripePromise} options={options}>
          <StripePaymentElement section={section} form={form}/>
        </Elements>
    </BaseSection>
    );
};

StripePaymentPlan.propTypes = {};

export default StripePaymentPlan;